import { AxiosResponse } from 'axios'
import { endOfDay, startOfDay } from 'date-fns'
import { Dispatch, SetStateAction } from 'react'

import { LogError } from 'utils'

import {
  Attachment,
  Carrier,
  Document,
  DocumentType,
  Provider as FastAttachProvider,
  Patient,
  PatientInsuranceResponse,
  ProviderAssociationResponse,
  ProviderRequest,
  ProviderSpecialtyTypes,
  Recipient,
  TrellisRelationshipTypes,
} from '../../../api/attachment-standalone/attachment-standalone-client'
import {
  EligibilityPatient,
  GetPatientEligibilityInfoResponse,
} from '../../../api/patients/patients-client'
import { PostPatient, PutPatient } from '../../../api/patients/patientsApi'
import {
  ProviderType,
  TrellisProvider,
  TrellisProviderInfo,
} from '../../../api/provider/providerApi'
import api, { IAuthenticatedApiModel } from '../../../utilities/api'
import { getFaCarrierById } from '../../../utilities/carrierHelpers'
import { formatDateFromString, showMessage } from '../../../utilities/general'
import {
  CarrierInfo,
  emptyPatientInfo,
  ImageRules,
  PatientInfo,
} from '../AttachmentsTypes'
import {
  CreateFastAttachProvider,
  DocumentByIdStoragePath,
  DocumentStoragePath,
  GetAttachment,
  GetAttachmentRules,
  GetFastAttachPatient,
  GetFastAttachPatientInsurance,
  GetRecipient,
  GetRecipientMasterId,
  UpdateFastAttachProvider,
  UploadImageToS3,
} from './../../../api/attachment-standalone/attachmentStandaloneApi'

export const editAttachmentSetup = async (
  authentication: IAuthenticatedApiModel,
  setLoading: Dispatch<SetStateAction<boolean>>,
  attachmentId: number,
  carrier: CarrierInfo,
  setCarrier: Dispatch<SetStateAction<CarrierInfo>>,
  setAttachment: Dispatch<SetStateAction<Attachment>>,
  setServiceInfoType: Dispatch<SetStateAction<number>>,
  setNarrativeText: Dispatch<SetStateAction<string>>,
  setDateRange: Dispatch<
    SetStateAction<{ dates: [Date, Date]; numberOfDays: number }>
  >,
  setImageTypes: Dispatch<SetStateAction<DocumentType[]>>,
  setImageRules: Dispatch<SetStateAction<ImageRules>>,
  setAttachmentLoaded: Dispatch<SetStateAction<boolean>>,
  patientInfo: PatientInfo,
  setPatientInfo: Dispatch<SetStateAction<PatientInfo>>,
  trellisProviderDetails: TrellisProviderInfo,
  setEditPatient: Dispatch<SetStateAction<boolean>>,
  carrierList: Carrier[],
) => {
  setLoading(true)

  await GetAttachment(attachmentId)
    .then(async ({ data }) => {
      const carrierInfoCopy = {
        ...carrier,
        carrierReferenceNumber: data.recipientReferenceNumber,
        carrierMasterId: data.masterId,
      }
      const attachmentCarrier = getFaCarrierById(carrierList, data.masterId)

      if (attachmentCarrier) {
        carrierInfoCopy.carrierName = attachmentCarrier.name
      } else {
        const recipient = await getRecipient(data.masterId)
        carrierInfoCopy.carrierName =
          recipient.recipientName ?? recipient.payorName
      }
      setCarrier(carrierInfoCopy)

      setAttachment(data)
      setServiceInfoType(
        data.priorAuthorization
          ? 2
          : data.serviceDateFrom || data.serviceDateTo
            ? 1
            : 0,
      )
      setNarrativeText(data.narrative)

      if (!data.priorAuthorization) {
        if (data.serviceDateFrom || data.serviceDateTo) {
          const fromDate = (data.serviceDateFrom ?? data.serviceDateTo).split(
            '-',
          )
          const toDate = (data.serviceDateTo ?? data.serviceDateFrom).split('-')
          setDateRange({
            dates: [
              startOfDay(
                new Date(`${fromDate[1]}-${fromDate[2]}-${fromDate[0]}`),
              ),
              endOfDay(new Date(`${toDate[1]}-${toDate[2]}-${toDate[0]}`)),
            ],
            numberOfDays: 0,
          })
        } else {
          setDateRange(null)
        }
      }

      const fastAttachPatient = await getFastAttachPatient(data.patientId)
      const fastAttachPatientInsurance = await getFastAttachPatientInsurance(
        data.patientId,
        data.patientInsuranceId,
      )

      // Checking to see if current attachment provider ID has a match in trellis provider list
      const trellisProviderMatch = getTrellisProviderByFaId(
        trellisProviderDetails,
        data.providerId,
      )

      let patientInfoCopy: PatientInfo = {
        ...patientInfo,
        patientFirstName: fastAttachPatient.firstName,
        patientLastName: fastAttachPatient.lastName,
        patientDateOfBirth: formatPatientDateOfBirth(
          fastAttachPatient.dateOfBirth,
        ),
        subscriberRelationship: fastAttachPatientInsurance.relationshipType,
        subscriberFirstName: fastAttachPatientInsurance.subscriberFirstName,
        subscriberLastName: fastAttachPatientInsurance.subscriberLastName,
        insuranceIsPrimary: true, // Always set to true on the front end for now
        memberId: fastAttachPatientInsurance.memberId,

        //Carrier
        carrierName: carrierInfoCopy.carrierName,
        carrierId: carrierInfoCopy.carrierId,
        masterId: carrierInfoCopy.carrierMasterId,

        //Provider, this is set below in updatePatientProviderInformation
        providerId: null,
        providerFullName: null,
        providerFirstName: null,
        providerLastName: null,
        providerNPI: null,
        providerServiceType: null,
        providerLicenseNumber: null,
        providerTaxId: null,
        providerTaxonomyCode: null,
        providerIsDefault: null,
      }

      patientInfoCopy = updatePatientProviderFields(
        patientInfoCopy,
        trellisProviderMatch,
      )

      patientInfoCopy = updatePatientCarrierFieldsById(
        patientInfoCopy,
        carrierList,
      )

      setPatientInfo(patientInfoCopy)

      await setupAttachmentRules(data.masterId, setImageTypes, setImageRules)

      if (!trellisProviderMatch) {
        setEditPatient(true)
        showMessage(
          'No matching provider in Trellis for the provider ID on the existing attachment. Please update and save.',
        )
        return
      }
    })
    .catch((e) => {
      setLoading(false)
      showMessage('Something went wrong while loading the attachment.')
    })
    .finally(() => {
      setLoading(false)
      setAttachmentLoaded(true)
    })
}

export const updatePatientCarrierFieldsById = (
  patientInfo: PatientInfo,
  carrierList: Carrier[],
): PatientInfo => {
  const match: Carrier = getFaCarrierById(
    carrierList,
    patientInfo.masterId,
    patientInfo.carrierId,
  )

  return updatePatientCarrierFields(patientInfo, match)
}

export const updatePatientCarrierFields = (
  patientInfo: PatientInfo,
  carrier: Carrier,
): PatientInfo => {
  return {
    ...patientInfo,
    carrierId: carrier?.payerTxtId,
    carrierName: carrier?.name,
    masterId: carrier?.neaMasterId,
  }
}

export const getTrellisProviderByFaId = (
  trellisProviderDetails: TrellisProviderInfo,
  id: number,
): TrellisProvider => {
  let trellisProviderMatch: TrellisProvider
  if (id) {
    trellisProviderMatch = trellisProviderDetails?.treatingProviders?.find(
      (provider) => provider.fastAttachProviderID === id,
    )
  }
  return trellisProviderMatch
}

export const setupAttachmentRules = async (
  masterId: number,
  setImageTypes: Dispatch<SetStateAction<DocumentType[]>>,
  setImageRules: Dispatch<SetStateAction<ImageRules>>,
) => {
  await GetAttachmentRules(masterId).then(({ data }) => {
    setImageTypes(data.documentTypes)
    setImageRules({
      narrativeDisabled: data.narrativeDisabled,
      recipientReferenceNumberDisabled: data.recipientReferenceNumberDisabled,
      recipientReferenceNumberRequired: data.recipientReferenceNumberRequired,
      claimPriorAuthorizationDisabled: data.claimPriorAuthorizationDisabled,
    })
  })
}

const formatTrellisProviderName = (
  provider: TrellisProvider,
): string => {
  return (
    provider?.providerFirstName &&
    provider?.providerLastName &&
    `${provider.providerFirstName} ${provider.providerLastName}`
  )
}

const formatPatientDateOfBirth = (dateOfBirth: string) => {
  return formatDateFromString(
    dateOfBirth,
    'MM/dd/yyy',
    'Failed to handle date of birth',
  )
}

export const newAttachmentSetup = async (
  authentication: IAuthenticatedApiModel,
  trellisProviderDetails: TrellisProviderInfo,
  setPatientInfo: Dispatch<SetStateAction<PatientInfo>>,
  setEditPatient: Dispatch<SetStateAction<boolean>>,
) => {
  const trellisDefaultProvider: TrellisProvider =
    trellisProviderDetails.defaultTreatingProvider

  const initialPatientInfo: PatientInfo = {
    ...emptyPatientInfo,
    //Provider
    providerId: trellisDefaultProvider?.eligibilityDefaultProviderID,
    providerFullName: formatTrellisProviderName(trellisDefaultProvider),
    providerFirstName: trellisDefaultProvider?.providerFirstName,
    providerLastName: trellisDefaultProvider?.providerLastName,
    providerNPI: trellisDefaultProvider?.providerNPI,
    providerServiceType: trellisDefaultProvider?.serviceType,
    providerLicenseNumber: trellisDefaultProvider?.licenseNumber,
    providerTaxId: trellisDefaultProvider?.taxId,
    providerTaxonomyCode: trellisDefaultProvider?.taxonomyCode,
    providerIsDefault: trellisDefaultProvider?.isDefault,
  }

  setPatientInfo(initialPatientInfo)
  setEditPatient(true)
}

export const updatePatientProviderFieldsOrDefault = (
  patientInfo: PatientInfo,
  fastAttachProviderId: number,
  trellisProviderDetails: TrellisProviderInfo,
): PatientInfo => {
  const patientProvider =
    getTrellisProviderByFaId(trellisProviderDetails, fastAttachProviderId) ??
    trellisProviderDetails.defaultTreatingProvider
  return updatePatientProviderFields(patientInfo, patientProvider)
}

export const updatePatientProviderFields = (
  patientInfo: PatientInfo,
  provider: TrellisProvider,
): PatientInfo => {
  return {
    ...patientInfo,
    providerId: provider?.eligibilityDefaultProviderID,
    fastAttachProviderId: provider?.fastAttachProviderID,
    providerFullName: formatTrellisProviderName(provider),
    providerFirstName: provider?.providerFirstName,
    providerLastName: provider?.providerLastName,
    providerServiceType: provider?.serviceType,
    providerTaxId: provider?.taxId,
    providerLicenseNumber: provider?.licenseNumber,
    providerNPI: provider?.providerNPI,
    providerTaxonomyCode: provider?.taxonomyCode,
    providerIsDefault: provider?.isDefault,
  }
}

export const updatePatientCarrierInformation = (
  patientInfo: PatientInfo,
): PatientInfo => {
  return {
    ...patientInfo,
  }
}

const mapPatientInfoToEligibilityPatient = (
  patientInfo: PatientInfo,
): EligibilityPatient => {
  const eligPatient: EligibilityPatient = {
    PatientId: patientInfo?.patientId ?? '',
    SyncId: patientInfo?.syncId,
    FastAttachPatientId: patientInfo?.fastAttachPatientId,
    FastAttachProviderId: patientInfo?.fastAttachProviderId,
    FastAttachInsuranceId: patientInfo.fastAttachInsuranceId,
    PatientFirstName: patientInfo?.patientFirstName,
    PatientMiddleName: patientInfo?.patientMiddleName,
    PatientLastName: patientInfo?.patientLastName,
    PatientSuffix: patientInfo?.patientSuffix,
    PatientGender: patientInfo?.patientGender,
    GroupNumber: patientInfo?.groupNumber,
    SubscriberFirstName:
      patientInfo?.subscriberRelationship !== 'Self'
        ? patientInfo.subscriberFirstName
        : patientInfo.patientFirstName,
    SubscriberMiddleName:
      patientInfo?.subscriberRelationship !== 'Self'
        ? patientInfo.subscriberMiddleName
        : patientInfo.patientMiddleName,
    SubscriberLastName:
      patientInfo?.subscriberRelationship !== 'Self'
        ? patientInfo.subscriberLastName
        : patientInfo.patientLastName,
    SubscriberSuffix:
      patientInfo?.subscriberRelationship !== 'Self'
        ? patientInfo?.subscriberSuffix
        : patientInfo.patientSuffix,
    SubscriberGender:
      patientInfo?.subscriberRelationship !== 'Self'
        ? patientInfo.subscriberGender
        : patientInfo.patientGender,
    SubscriberBirthdate:
      patientInfo?.subscriberRelationship !== 'Self'
        ? patientInfo?.subscriberBirthDate &&
          new Date(patientInfo.subscriberBirthDate).toISOString()
        : patientInfo?.patientDateOfBirth &&
          new Date(patientInfo.patientDateOfBirth).toISOString(),
    PatientBirthdate:
      patientInfo?.patientDateOfBirth &&
      new Date(patientInfo.patientDateOfBirth).toISOString(),
    SubscriberId: patientInfo?.memberId,
    PatientIsSub: patientInfo?.subscriberRelationship === 'Self',
    ProviderFirstName: patientInfo?.providerFirstName,
    ProviderLastName: patientInfo?.providerLastName,
    IndividualNpi: patientInfo?.providerNPI,
    CarrierId: patientInfo?.carrierId,
    CarrierName: patientInfo?.carrierName,
    CarrierMasterId: patientInfo.masterId,
  }
  return eligPatient
}

const mapTrellisPatientToPatientInfo = (
  vdsPatientResponse: GetPatientEligibilityInfoResponse,
  patientProvider: TrellisProvider,
  carrierList: Carrier[],
) => {
  //base patient state for the edit window, if we pass validation it'll be overwritten
  let initialPatientInfo: PatientInfo = {
    patientId: vdsPatientResponse?.PatientId,
    syncId: vdsPatientResponse?.SyncId,
    fastAttachPatientId: vdsPatientResponse?.FastAttachPatientId,
    fastAttachInsuranceId: vdsPatientResponse?.FastAttachInsuranceId,
    fastAttachProviderId: vdsPatientResponse.FastAttachProviderId,
    patientFirstName: vdsPatientResponse?.PatientFirstName,
    patientMiddleName: vdsPatientResponse?.PatientMiddleName,
    patientLastName: vdsPatientResponse?.PatientLastName,
    patientSuffix: vdsPatientResponse?.PatientSuffix,
    patientGender: vdsPatientResponse?.PatientGender,
    subscriberRelationship: vdsPatientResponse?.RelationshipToSubscriber
      ? vdsPatientResponse?.RelationshipToSubscriber
      : vdsPatientResponse?.PatientIsSub
        ? TrellisRelationshipTypes.Self
        : TrellisRelationshipTypes.Other,
    subscriberFirstName: vdsPatientResponse?.PatientIsSub
      ? ''
      : vdsPatientResponse?.SubscriberFirstName,
    subscriberMiddleName: vdsPatientResponse?.PatientIsSub
      ? ''
      : vdsPatientResponse?.SubscriberMiddleName,
    subscriberLastName: vdsPatientResponse?.PatientIsSub
      ? ''
      : vdsPatientResponse?.SubscriberLastName,
    subscriberSuffix: vdsPatientResponse?.PatientIsSub
      ? ''
      : vdsPatientResponse?.SubscriberSuffix,
    subscriberGender: vdsPatientResponse?.PatientIsSub
      ? ''
      : vdsPatientResponse?.SubscriberGender,
    subscriberBirthDate: vdsPatientResponse?.PatientIsSub
      ? ''
      : vdsPatientResponse?.SubscriberBirthdate &&
        formatPatientDateOfBirth(vdsPatientResponse.SubscriberBirthdate),
    insuranceIsPrimary: true, // Always set to true on the front end
    patientDateOfBirth:
      vdsPatientResponse?.PatientBirthdate &&
      formatPatientDateOfBirth(vdsPatientResponse.PatientBirthdate),

    memberId: vdsPatientResponse?.SubscriberId,
    groupNumber: vdsPatientResponse?.GroupNumber,

    //Carrier, only need the ids, the rest are set below by updatePatientCarrierFieldsByIds
    carrierId: vdsPatientResponse?.CarrierId ?? '',
    masterId: vdsPatientResponse?.CarrierMasterId,
    carrierName: null,

    //Provider, this is set below in updatePatientProviderInformation
    providerId: null,
    providerFullName: null,
    providerFirstName: null,
    providerLastName: null,
    providerNPI: null,
    providerServiceType: null,
    providerLicenseNumber: null,
    providerTaxId: null,
    providerTaxonomyCode: null,
    providerIsDefault: null,
  }
  initialPatientInfo = updatePatientProviderFields(
    initialPatientInfo,
    patientProvider,
  )

  initialPatientInfo = updatePatientCarrierFieldsById(
    initialPatientInfo,
    carrierList,
  )
  return initialPatientInfo
}

export const getIdsForNewAttachment = async (
  authentication: IAuthenticatedApiModel,
  trellisAuthentication: IAuthenticatedApiModel,
  facilityId: string,
  vdsPatientResponse: GetPatientEligibilityInfoResponse,
  trellisProviderDetails: TrellisProviderInfo,
  setVdsPatientResponse: Dispatch<
    SetStateAction<GetPatientEligibilityInfoResponse>
  >,
  setEditPatient: Dispatch<SetStateAction<boolean>>,
  setAttachment: Dispatch<SetStateAction<Attachment>>,
  setAttachmentLoaded: Dispatch<SetStateAction<boolean>>,
  setPatientInfo: Dispatch<SetStateAction<PatientInfo>>,
  setImageTypes: Dispatch<SetStateAction<DocumentType[]>>,
  setImageRules: Dispatch<SetStateAction<ImageRules>>,
  carrierList: Carrier[],
) => {
  try {
    const patientProvider =
      getTrellisProviderByFaId(
        trellisProviderDetails,
        vdsPatientResponse?.FastAttachProviderId,
      ) ?? trellisProviderDetails.defaultTreatingProvider
    //base patient state for the edit window
    let initialPatientInfo: PatientInfo = mapTrellisPatientToPatientInfo(
      vdsPatientResponse,
      patientProvider,
      carrierList,
    )

    setPatientInfo(initialPatientInfo)

    if (!initialPatientInfo.masterId && initialPatientInfo.carrierId) {
      // Fast Attach Master ID
      const recipientResponse: Recipient = await getRecipientMasterId(
        vdsPatientResponse.CarrierId,
      )
      initialPatientInfo.masterId = recipientResponse.masterId
    }

    if (!initialPatientInfo?.masterId && initialPatientInfo?.masterId !== 0) {
      setEditPatient(true)
      showMessage(
        'Selected patient is missing required carrier information. Please update and save.',
      )
      return
    }
    setPatientInfo({
      ...initialPatientInfo,
    })

    await setupAttachmentRules(
      initialPatientInfo.masterId,
      setImageTypes,
      setImageRules,
    )

    // Missing required trellis provider ID
    if (!patientProvider || !checkForValidFields(patientProvider)) {
      setEditPatient(true)
      showMessage(
        'Selected patient is missing required provider information. Please update and save.',
      )
      return
    }

    // Patient is not subscriber, coming from VDS patient search
    // VDS search doesn't give us the subscriber type so we need more details
    if (
      !vdsPatientResponse?.PatientIsSub &&
      !vdsPatientResponse?.SubscriberId
    ) {
      showMessage(
        'Selected patient is not subscriber and is missing required subscriber information. Please update and save.',
      )
      setEditPatient(true)
      return
    }

    // Fast Attach Provider
    const providerRequest: ProviderRequest = {
      facilityId: facilityId,
      firstName: patientProvider?.providerFirstName,
      lastName: patientProvider?.providerLastName,
      taxId: patientProvider?.taxId,
      licenseNumber: patientProvider?.licenseNumber,
      npi: patientProvider?.providerNPI,
      specialtyType: ProviderSpecialtyTypes.GeneralDentist,
    }

    const fastAttachProviderResponse: ProviderAssociationResponse =
      await createFastAttachProvider(
        authentication,
        patientProvider?.eligibilityDefaultProviderID,
        providerRequest,
      )

    if (!fastAttachProviderResponse.fastAttachProviderId) {
      setEditPatient(true)
      showMessage(
        'Selected patient is missing required provider information. Please update and save.',
      )
      return
    }

    if (!initialPatientInfo.fastAttachPatientId) {
      //They don't exist on FA but pass validation so we need to trigger an update so their info is sent over and we have a FA id for them
      const patientUpdateResponse: GetPatientEligibilityInfoResponse = (
        await savePatient(trellisAuthentication, initialPatientInfo)
      )?.data

      // Missing required patient ID for patient insurance API call
      if (!patientUpdateResponse?.FastAttachPatientId) {
        setEditPatient(true)
        showMessage(
          'Selected patient is missing required patient information. Please update and save.',
        )
        return
      } else if (
        !patientUpdateResponse?.FastAttachInsuranceId ||
        !patientUpdateResponse?.CarrierMasterId
      ) {
        setEditPatient(true)
        showMessage(
          'Selected patient is missing required insurance information. Please update and save.',
        )
        return
      }
      initialPatientInfo = mapTrellisPatientToPatientInfo(
        patientUpdateResponse,
        patientProvider,
        carrierList,
      )
    }

    const newAttachment: Attachment = {
      attachmentId: null,
      statusId: 2,
      facilityId,
      patientId: initialPatientInfo?.fastAttachPatientId,
      providerId: initialPatientInfo?.fastAttachProviderId || 0,
      patientInsuranceId: initialPatientInfo?.fastAttachInsuranceId,
      masterId: initialPatientInfo.masterId,
      dmsReferenceNumber: null,
      priorAuthorization: false,
      serviceDateFrom: null,
      serviceDateTo: null,
      narrative: null,
      documents: [],
      procedureCodeRequirements: [],
    }

    setAttachment(newAttachment)
    setPatientInfo(initialPatientInfo)
    setVdsPatientResponse(null)
    setAttachmentLoaded(true)
  } catch (e: any) {
    setEditPatient(true)
    throw e
  }
}

const savePatient = async (
  trellisAuthentication: IAuthenticatedApiModel,
  patInfo: PatientInfo,
): Promise<AxiosResponse<GetPatientEligibilityInfoResponse>> => {
  try {
    const patientRequest = mapPatientInfoToEligibilityPatient(patInfo)

    if (patientRequest.PatientId && patientRequest.PatientId !== '') {
      return await PutPatient(
        trellisAuthentication,
        patientRequest.PatientId,
        patientRequest,
      )
    } else {
      return await PostPatient(trellisAuthentication, patientRequest)
    }
  } catch (error) {
    LogError(error, 'Failed to save patient')
  }
  return null
}

export const updateAttachmentPatientInformation = async (
  authentication: IAuthenticatedApiModel,
  trellisAuthentication: IAuthenticatedApiModel,
  facilityId: string,
  vdsPatientResponse: GetPatientEligibilityInfoResponse,
  setVdsPatientResponse: Dispatch<
    SetStateAction<GetPatientEligibilityInfoResponse>
  >,
  patientInfo: PatientInfo,
  attachment: Attachment,
  setAttachment: Dispatch<SetStateAction<Attachment>>,
  fastAttachProviderId: number,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  setAttachmentLoaded: Dispatch<SetStateAction<boolean>>,
  setImageTypes: Dispatch<SetStateAction<DocumentType[]>>,
  setImageRules: Dispatch<SetStateAction<ImageRules>>,
  setPatientInfo: Dispatch<SetStateAction<PatientInfo>>,
) => {
  // Fast Attach Patient
  let fastAttachPatientResponse: AxiosResponse<GetPatientEligibilityInfoResponse>
  const patientRequest = mapPatientInfoToEligibilityPatient(patientInfo)

  if (attachment) {
    patientRequest.FastAttachPatientId = attachment.patientId
  }

  fastAttachPatientResponse = await savePatient(
    trellisAuthentication,
    patientInfo,
  )

  const patientInfoCopy = { ...patientInfo }
  patientInfoCopy.fastAttachPatientId =
    fastAttachPatientResponse?.data?.FastAttachPatientId
  patientInfoCopy.masterId = fastAttachPatientResponse?.data?.CarrierMasterId
  patientInfoCopy.fastAttachInsuranceId =
    fastAttachPatientResponse?.data?.FastAttachInsuranceId

  if (!patientInfoCopy.fastAttachPatientId) {
    showMessage(
      'There was an issue saving your patient details, please try again.',
    )
    return
  } else if (
    patientInfoCopy.masterId == null ||
    patientInfoCopy.masterId < 0 ||
    !patientInfoCopy.fastAttachInsuranceId
  ) {
    showMessage(
      'There was an issue saving your patient insurance details, please try again.',
    )
    return
  }
  setPatientInfo(patientInfoCopy)

  let attachmentCopy: Attachment
  if (attachment) {
    attachmentCopy = {
      ...attachment,
      patientId: patientInfoCopy.fastAttachPatientId,
      providerId: fastAttachProviderId,
      patientInsuranceId: patientInfoCopy.fastAttachInsuranceId,
      masterId: patientInfoCopy.masterId,
    }

    setAttachment(attachmentCopy)
  } else {
    attachmentCopy = {
      attachmentId: null,
      statusId: 2,
      facilityId,
      patientId: patientInfoCopy.fastAttachPatientId,
      providerId: fastAttachProviderId,
      patientInsuranceId: patientInfoCopy.fastAttachInsuranceId,
      masterId: patientInfoCopy.masterId,
      dmsReferenceNumber: null,
      priorAuthorization: false,
      serviceDateFrom: null,
      serviceDateTo: null,
      narrative: null,
      documents: [],
      procedureCodeRequirements: [],
    }

    setAttachment(attachmentCopy)
  }

  await setupAttachmentRules(patientInfo.masterId, setImageTypes, setImageRules)

  setIsOpen(false)
  if (vdsPatientResponse) setVdsPatientResponse(null)
  setAttachmentLoaded(true)
}

export const handleAttachmentDocumentUpload = async (
  authentication: IAuthenticatedApiModel,
  facilityId: string,
  document: Document,
  documentFile: File,
) => {
  let documentFilename = {} as string
  if (document?.documentId) {
    // get presigned url by documentId
    await DocumentByIdStoragePath(facilityId, document.documentId)
      .then(async ({ data }) => {
        await UploadImageToS3(data.documentPath, documentFile)
        documentFilename = data.documentName
      })
      .catch((e) => {})
  } else {
    await DocumentStoragePath(facilityId, parseInt(authentication.CustomerId))
      .then(async ({ data }) => {
        await UploadImageToS3(data.documentPath, documentFile)
        documentFilename = data.documentName
      })
      .catch((e) => {})
  }
  return documentFilename
}

// Helper functions

const checkForValidFields = (p: TrellisProvider) => {
  return (
    p?.eligibilityDefaultProviderID &&
    p?.providerFirstName &&
    p?.providerLastName &&
    p?.providerNPI &&
    p?.taxId &&
    p?.licenseNumber &&
    p?.taxonomyCode
  )
}

export const addTrellisProvider = async (
  authentication: IAuthenticatedApiModel,
  facilityId: string,
  patientInfo: PatientInfo,
  isNewProvider: boolean,
  trellisProviderDetails: TrellisProviderInfo,
  getTrellisProviderDetails: (refresh?: boolean) => Promise<void>,
) => {
  const trellisProviderRequest: TrellisProvider = {
    eligibilityDefaultProviderID: isNewProvider ? 0 : patientInfo?.providerId,
    fastAttachProviderID: patientInfo?.fastAttachProviderId,
    globalCustomerID: parseInt(authentication.CustomerId),
    isDefault: patientInfo?.providerIsDefault,
    licenseNumber: patientInfo?.providerLicenseNumber,
    providerFirstName: patientInfo?.providerFirstName,
    providerLastName: patientInfo?.providerLastName,
    providerNPI: patientInfo?.providerNPI,
    serviceType: ProviderType.Treatment,
    taxId: patientInfo?.providerTaxId,
    taxonomyCode: patientInfo?.providerTaxonomyCode,
    addDate: new Date().toISOString(),
    lastUpdateDate: new Date().toISOString(),
  }

  let trellisProviderData: {
    statusCode: number
    message: string
    provider?: TrellisProvider
    fastAttachProviderId?: number
  }
  await api
    .addProvider(trellisProviderRequest)
    .then(({ data }) => {
      trellisProviderData = data
    })
    .catch((e) => {
      throw new Error(e.message)
    })

  // Fast Attach Provider
  const fastAttachProviderRequest: ProviderRequest = {
    facilityId,
    firstName: patientInfo?.providerFirstName,
    lastName: patientInfo?.providerLastName,
    taxId: patientInfo?.providerTaxId,
    licenseNumber: patientInfo?.providerLicenseNumber,
    npi: patientInfo?.providerNPI,
    specialtyType: ProviderSpecialtyTypes.GeneralDentist,
  }

  let fastAttachUpdateProviderResponse: FastAttachProvider
  let fastAttachCreateProviderResponse: ProviderAssociationResponse

  const match = trellisProviderDetails.treatingProviders.find(
    (provider) =>
      provider.eligibilityDefaultProviderID ===
      trellisProviderData.provider.eligibilityDefaultProviderID,
  )

  if (match?.fastAttachProviderID) {
    fastAttachUpdateProviderResponse = await updateFastAttachProvider(
      authentication,
      match.fastAttachProviderID,
      fastAttachProviderRequest,
    )
    trellisProviderData.fastAttachProviderId =
      fastAttachUpdateProviderResponse.providerId
  } else {
    fastAttachCreateProviderResponse = await createFastAttachProvider(
      authentication,
      trellisProviderData.provider.eligibilityDefaultProviderID,
      fastAttachProviderRequest,
    )
    trellisProviderData.fastAttachProviderId =
      fastAttachCreateProviderResponse.fastAttachProviderId
  }

  await getTrellisProviderDetails(true) //refresh the providers

  return trellisProviderData
}

export const updateFastAttachProvider = async (
  authentication: IAuthenticatedApiModel,
  providerId: number,
  providerRequest: ProviderRequest,
) => {
  let fastAttachProviderResponse: ProviderAssociationResponse
  await UpdateFastAttachProvider(providerId, providerRequest)
    .then(async ({ data }) => {
      fastAttachProviderResponse = data
    })
    .catch((e) => {
      throw new Error(e.message)
    })
  return fastAttachProviderResponse
}

const createFastAttachProvider = async (
  authentication: IAuthenticatedApiModel,
  trellisProviderId: number,
  providerRequest: ProviderRequest,
) => {
  let fastAttachProviderResponse: ProviderAssociationResponse
  await CreateFastAttachProvider(
    parseInt(authentication.CustomerId),
    trellisProviderId,
    providerRequest,
  )
    .then(async ({ data }) => {
      fastAttachProviderResponse = data
    })
    .catch((e) => {
      throw new Error(e.message)
    })
  return fastAttachProviderResponse
}

const getFastAttachPatient = async (patientId: number) => {
  let fastAttachPatient: Patient
  await GetFastAttachPatient(patientId)
    .then(({ data }) => {
      fastAttachPatient = data
    })
    .catch((e) => {
      throw new Error(e.message)
    })
  return fastAttachPatient
}

const getRecipient = async (masterId: number) => {
  let recipient: Recipient
  await GetRecipient(masterId)
    .then(({ data }) => {
      recipient = data
    })
    .catch((e) => {
      throw new Error(e.message)
    })
  return recipient
}

const getRecipientMasterId = async (carrierId: string) => {
  let recipientResponse: Recipient
  await GetRecipientMasterId(carrierId)
    .then(({ data }) => {
      recipientResponse = data
    })
    .catch((e) => {
      throw new Error(e.message)
    })
  return recipientResponse
}

const getFastAttachPatientInsurance = async (
  patientId: number,
  insuranceId: number,
) => {
  let fastAttachPatientInsurance: PatientInsuranceResponse
  await GetFastAttachPatientInsurance(patientId, insuranceId)
    .then(({ data }) => {
      fastAttachPatientInsurance = data
    })
    .catch((e) => {
      throw new Error(e.message)
    })
  return fastAttachPatientInsurance
}
