import { observer } from '@legendapp/state/react'
import { FC, useEffect, useState } from 'react'

import { LoadingSpinner } from 'ui'
import { LogError } from 'utils'

import { TAB_NAMES } from 'trellis:components/MyPractice/MyPractice'
import { showMessage } from 'trellis:utilities/general'
import { getBillingAndSubscriptionUrl } from 'trellis:utilities/ssoHelper'

import styles from './billingAndSubscription.module.scss'

type BillingAndSubscriptionProps = {
  activeTab: string
}

export const BillingAndSubscription: FC<BillingAndSubscriptionProps> = observer(
  ({ activeTab }) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [billingAndSubscriptionUrl, setBillingAndSubscriptionUrl] =
      useState<string>()
    const [showBillingAndSubscription, setShowBillingAndSubscription] =
      useState<boolean>(false)

    useEffect(() => {
      setLoading(true)
      try {
        if (activeTab === TAB_NAMES.BILLING_AND_SUBSCRIPTION) {
          setShowBillingAndSubscription(true)
          ;(async () => {
            const url = await getBillingAndSubscriptionUrl()

            setBillingAndSubscriptionUrl(url)
          })()
        } else {
          setShowBillingAndSubscription(false)
        }
      } catch (error) {
        LogError(error, 'Failed to obtain billing and subscription ssoUrl')
        showMessage(
          'There was an error displaying billing and subscription. Please try again.',
        )
      } finally {
        setLoading(false)
      }
    }, [activeTab])

    if (loading) return <LoadingSpinner />

    if (!showBillingAndSubscription) return null

    return !billingAndSubscriptionUrl ? (
      ''
    ) : (
      <article className={styles['billing-and-subscription__container']}>
        <iframe
          id='iframe_billingAndSubscription'
          src={billingAndSubscriptionUrl}
          title='Billing and Subscription Microsite'
          allow='clipboard-read; clipboard-write'
          style={{
            width: '100%',
            height: '62vh',
            margin: '5px',
            overflow: 'hidden',
          }}
        ></iframe>
      </article>
    )
  },
)
