import React from 'react';
import { LDFlags$ } from 'trellis:state/globalState';

import { Tooltip } from 'antd';
import { Labels } from 'trellis:constants/labels';
import { useClaimControlContext } from 'trellis:components/claims/context/claimControlContext';
import { VyneButton } from '@vynedental/design-system';
import { FileDownloadOutlined, Inventory2Outlined, PrintOutlined, SettingsOutlined, SyncOutlined, ViewColumnOutlined, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { Observable } from '@legendapp/state';

type VisibilityState = {
  showClaimSettingsModal$: Observable<boolean>
  showClaimColumnSelector$: Observable<boolean>
  showArchiveSearchModal$: Observable<boolean>
}

const useGridToolbarActions = (activeTab: string, isPmg: boolean, viewIgnored: boolean, visibilityState: VisibilityState) => {
  const flags = LDFlags$.get()

  const {
      toggleIgnore,
      updateClaims,
      download,
      print,
      pulse,
    } = useClaimControlContext()

  const items = [
    {
      key: 'update-claims',
      label: 'Update',
      icon: <SyncOutlined style={{ fontSize: '1rem'}} />,
      onClick: updateClaims,
      button: (
        <Tooltip title={Labels.updateClaims} mouseEnterDelay={0.5}>
          <VyneButton 
            className={`grid-control-button ${pulse ? 'pulse' : ''}`}
            dataTestId='grid-toolbar-update-icon' 
            icon={<SyncOutlined />}
            onClick={updateClaims} 
          />
        </Tooltip>
      ),
    },
    {
      key: 'print-claims',
      label: 'Print',
      icon: <PrintOutlined style={{ fontSize: '1rem'}} />,
      onClick: print,
      button: (
        <Tooltip title='Print' mouseEnterDelay={0.5}>
          <VyneButton 
            className='grid-control-button'
            dataTestId='grid-toolbar-print-icon'
            disabled={isPmg}
            icon={<PrintOutlined />}
            onClick={print}
          />
        </Tooltip>
      ),
    },
    {
      key: 'download-claims',
      label: 'Download',
      icon: <FileDownloadOutlined style={{ fontSize: '1rem'}} />,
      onClick: download,
      button: (
        <Tooltip title='Download' mouseEnterDelay={0.5}>
          <VyneButton 
            className='grid-control-button'
            dataTestId='grid-toolbar-download-icon'
            icon={<FileDownloadOutlined />}
            onClick={download}
          />
        </Tooltip>
      ),  
    },
    {
      key: 'claim-settings',
      label: 'Settings',
      icon: <SettingsOutlined style={{ fontSize: '1rem'}} />,
      onClick: () => flags.createAClaim ? visibilityState.showClaimSettingsModal$.set(true) : window.open('/Rlo/Settings', '_blank'),
      button: (
        <Tooltip title='Settings' mouseEnterDelay={0.5}>
          {flags.createAClaim ? (
            <VyneButton 
              className='grid-control-button'
              dataTestId='grid-toolbar-settings-icon'
              icon={<SettingsOutlined />}
              onClick={() => visibilityState.showClaimSettingsModal$.set(true)}
            />
          ) : (
            <a href='/Rlo/Settings' target='_blank'>
              <VyneButton 
                className='grid-control-button'
                dataTestId='grid-toolbar-settings-icon'
                icon={<SettingsOutlined />}
              />
            </a>
          )}
        </Tooltip>
      ),
    },
    {
      key: 'claim-column-selector',
      label: 'Columns',
      icon: <ViewColumnOutlined style={{ fontSize: '1rem'}} />,
      onClick: () => visibilityState.showClaimColumnSelector$.set(true),
      button: (
        <Tooltip title='Columns' mouseEnterDelay={0.5}>
          <VyneButton 
            className='grid-control-button'
            dataTestId='grid-toolbar-columns-icon'
            icon={<ViewColumnOutlined />}
            onClick={() => visibilityState.showClaimColumnSelector$.set(true)}
          />
        </Tooltip>
      ),
    },
  ].filter(Boolean)

  if (activeTab === 'Sent') {
    items.splice(1, 0, {
      key: 'claim-archive-search',
      label: 'Archive',
      icon: <Inventory2Outlined style={{ fontSize: '1rem'}} />,
      onClick: () => visibilityState.showArchiveSearchModal$.set(true),
      button: (
        <Tooltip title='Archive Claim Search' mouseEnterDelay={0.5}>
          <VyneButton 
            className='grid-control-button'
            dataTestId='grid-toolbar-archive-icon'
            icon={<Inventory2Outlined />}
            onClick={() => visibilityState.showArchiveSearchModal$.set(true)}
          />
        </Tooltip>
      ),
    })

    items.splice(4, 0, {
      key: 'claim-show-hidden',
      label: 'Show Hidden',
      icon: viewIgnored ? 
        <VisibilityOffOutlined style={{ fontSize: '1rem'}} /> : 
        <VisibilityOutlined style={{ fontSize: '1rem'}} />,
      onClick: toggleIgnore,
      button: (
        <Tooltip title={Labels.viewHidden} mouseEnterDelay={0.5}>
          {viewIgnored && (
            <VyneButton 
              className='grid-control-button'
              dataTestId='grid-toolbar-show-hidden-icon'
              icon={<VisibilityOutlined />}
              onClick={toggleIgnore}
            />
          )}
          {!viewIgnored && (
            <VyneButton 
              className='grid-control-button'
              dataTestId='grid-toolbar-show-hidden-icon'
              icon={<VisibilityOffOutlined />}
              onClick={toggleIgnore}
            />
          )}
        </Tooltip>
      ),
    })
  }
    

  const length = items.length
  const individualItems = items.slice(0, length > 3 ? 3 : length)
  const remainingItems = length > 3 ? items.slice(3) : null

  const dropdownItems = remainingItems || null

  return [individualItems, dropdownItems] as const;
};

export default useGridToolbarActions;