import { CheckCircleTwoTone } from '@ant-design/icons'
import { Col, Row, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Fuse from 'fuse.js'
import { FC, ReactNode, useEffect, useState } from 'react'

import { SearchBar, SearchStates } from 'ui'

import { CarrierDto } from 'trellis:api/carrier/carrier-client'
import { GetCarrierList } from 'trellis:api/carrier/carrierApi'
import { Errors } from 'trellis:constants/errors'
import { useGlobalContext } from 'trellis:context/GlobalContextProvider'
import FavoriteCell from 'trellis:features/carriers/_FavoriteCell'
import ServiceFilter from 'trellis:features/carriers/_ServiceFilter'
import { CarrierInfo } from 'trellis:features/carriers/CarrierInfo/CarrierInfo'
import FuseHighlight from 'trellis:utilities/fuzzySearch/FuseHighlight'
import { useFuse } from 'trellis:utilities/fuzzySearch/useFuse'
import { showMessage } from 'trellis:utilities/general'

export type CarrierListFilters = {
  currentPage: number
  pageSize: number
  service: keyof CarrierDto
}

export const CarrierList: FC = () => {
  const { authentication } = useGlobalContext()

  const [tableScrollHeight, setTableScrollHeight] = useState<number>(0)
  const [carrier, setCarrier] = useState<CarrierDto>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [carrierList, setCarrierList] = useState<CarrierDto[]>([])
  const [searchInput, setSearchInput] = useState<SearchStates>('')
  const [filters, setFilters] = useState<CarrierListFilters>({
    currentPage: 1,
    pageSize: 10,
    service: null,
  })

  useEffect(() => {
    const mainPageContentElement: HTMLElement = document.querySelector(
      '.main__page-content',
    )
    if (mainPageContentElement) resizeObserver.observe(mainPageContentElement)

    return () => resizeObserver.unobserve(mainPageContentElement)
  }, [])

  useEffect(() => {
    if (authentication?.AccessToken) {
      if (!carrierList.length) getCarriers()
      else {
        if (filters.currentPage !== 1)
          setFilters({ ...filters, currentPage: 1 })
        onSearch(searchInput as string)
      }
    }
  }, [authentication?.AccessToken, filters.service, searchInput])

  const resizeObserver = new ResizeObserver(() => {
    const tableElement: HTMLElement = document.querySelector('.ant-table-body')
    if (tableElement) {
      const scrollHeight =
        window.innerHeight -
        (Math.round(tableElement.getBoundingClientRect().top) + 110)
      setTableScrollHeight(scrollHeight)
    }
  })

  const filterListCallBack = (data: CarrierDto[]) => {
    let list: CarrierDto[] = JSON.parse(JSON.stringify(data))

    if (filters.service)
      list = list.filter((carrier) => carrier[filters.service])

    return list
  }

  const sortListCallBack = (data: CarrierDto[]) => {
    const list: CarrierDto[] = JSON.parse(JSON.stringify(data))

    if (!searchInput) {
      list.sort((a: CarrierDto, b: CarrierDto) => {
        let result: number = 0

        if (a.IsFavorite && b.IsFavorite) {
          if (a.Name < b.Name) result = -1
          else result = 1
        } else if (a.IsFavorite && !b.IsFavorite) result = -1
        else if (!a.IsFavorite && b.IsFavorite) result = 1
        else {
          if (a.Name < b.Name) result = -1
          else result = 1
        }

        return result
      })
    }

    return list
  }

  const { hitItems, hits, onSearch } = useFuse<CarrierDto>({
    data: carrierList, // TODO: figure out why data changes multiple times on load
    filterCallBack: filterListCallBack,
    matchAllOnEmptyQuery: true,
    options: { keys: ['Id', 'Name', 'Aliases'] },
    sortCallBack: sortListCallBack,
  })

  const getCarriers = async () => {
    setLoading(true)

    await GetCarrierList()
      .then(({ data }) => setCarrierList(data))
      .catch(() => showMessage(Errors.carrierListError))
      .finally(() => setLoading(false))
  }

  const handleCarrierPreferenceChange = (
    record: CarrierDto,
    isFavorite: boolean,
  ) => {
    record.IsFavorite = isFavorite

    let recordIndex: number
    const carriers: CarrierDto[] = JSON.parse(JSON.stringify(carrierList))
    carriers.forEach((item: CarrierDto, index: number) => {
      if (item.Id === record.Id) recordIndex = index
    })

    carriers.splice(recordIndex, 1, record)

    setCarrierList(carriers)
  }

  const tableColumns: ColumnsType<CarrierDto> = [
    {
      align: 'center',
      dataIndex: 'IsFavorite',
      render: (value: boolean, record: CarrierDto) => (
        <FavoriteCell
          handleCarrierPreferenceChange={handleCarrierPreferenceChange}
          record={record}
          value={value}
        />
      ),
      width: '3rem',
    },
    {
      dataIndex: 'Id',
      onCell: (record: CarrierDto) => ({ onClick: () => setCarrier(record) }),
      render: (value: string) => {
        let element: ReactNode = <>{value}</>

        hits.forEach((hit: Fuse.FuseResult<CarrierDto>) => {
          if (hit.item.Id === value) {
            element = (
              <FuseHighlight
                hit={hit}
                attribute='Id'
              />
            )
          }
        })

        return element
      },
      title: 'Carrier ID',
      width: '7rem',
    },
    {
      dataIndex: 'Name',
      onCell: (record: CarrierDto) => ({ onClick: () => setCarrier(record) }),
      render: (value: string) => {
        let element: ReactNode = <>{value}</>

        hits.forEach((hit: Fuse.FuseResult<CarrierDto>) => {
          if (hit.item.Name === value) {
            element = (
              <FuseHighlight
                hit={hit}
                attribute='Name'
              />
            )
          }
        })

        return element
      },
      title: 'Carrier Name',
      width: '20rem',
    },
    {
      dataIndex: 'PrimaryPhone',
      onCell: (record: CarrierDto) => ({ onClick: () => setCarrier(record) }),
      title: 'Primary Phone',
      width: '12rem',
    },
    {
      align: 'center',
      dataIndex: 'NeaMasterId',
      onCell: (record: CarrierDto) => ({ onClick: () => setCarrier(record) }),
      render: (value: boolean) =>
        value ? (
          <CheckCircleTwoTone twoToneColor='#91C73D' />
        ) : (
          <span>&#8211;</span>
        ),
      title: 'Attachment',
      width: '9rem',
    },
    {
      align: 'center',
      dataIndex: 'Eligibility',
      onCell: (record: CarrierDto) => ({ onClick: () => setCarrier(record) }),
      render: (value: boolean) =>
        value ? (
          <CheckCircleTwoTone twoToneColor='#91C73D' />
        ) : (
          <span>&#8211;</span>
        ),
      title: 'Eligibility',
      width: '9rem',
    },
    {
      align: 'center',
      dataIndex: 'Era',
      onCell: (record: CarrierDto) => ({ onClick: () => setCarrier(record) }),
      render: (value: boolean) =>
        value ? (
          <CheckCircleTwoTone twoToneColor='#91C73D' />
        ) : (
          <span>&#8211;</span>
        ),
      title: 'ERA',
      width: '9rem',
    },
    {
      align: 'center',
      dataIndex: 'RtClaims',
      onCell: (record: CarrierDto) => ({ onClick: () => setCarrier(record) }),
      render: (value: boolean) =>
        value ? (
          <CheckCircleTwoTone twoToneColor='#91C73D' />
        ) : (
          <span>&#8211;</span>
        ),
      title: 'Real-Time Claim',
      width: '9rem',
    },
  ]

  const updatePaging = (CurrentPage: number, PageSize: number) => {
    const filtersCopy = { ...filters }

    filtersCopy.currentPage = CurrentPage
    filtersCopy.pageSize = PageSize

    setFilters({ ...filtersCopy })
  }

  const pagination = {
    showSizeChanger: true,
    current: filters.currentPage,
    pageSize: filters.pageSize,
    total: hitItems?.length,
    showTotal: (total: number, range: [number, number]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    onChange: (page: number, pageSize: number) => updatePaging(page, pageSize),
  }

  return (
    <article className='page-content__container page-content__container--with-page-header'>
      {carrier && (
        <CarrierInfo
          authentication={authentication}
          carrier={carrier}
          handleCarrierPreferenceChange={handleCarrierPreferenceChange}
          setCarrier={setCarrier}
        />
      )}
      {!carrier && (
        <>
          <section>
            <h1>Carrier List</h1>
            <p>
              View carrier specific contact details and attachment requirements.
            </p>
          </section>
          <section>
            <Row
              align='middle'
              className='mt-150'
              gutter={32}
            >
              <Col>
                <SearchBar
                  disabled={loading}
                  placeholder='Search by Carrier ID or Name'
                  setState={setSearchInput}
                  state={searchInput}
                />
              </Col>
              <Col>
                <ServiceFilter
                  filters={filters}
                  loading={loading}
                  setFilters={setFilters}
                />
              </Col>
            </Row>
            <Table
              className='table--non-smart mt-100'
              columns={tableColumns}
              dataSource={hitItems}
              loading={loading}
              pagination={pagination}
              rowClassName={(_, i) => i % 2 !== 0 && 'table__row--gray'}
              rowKey='Id'
              scroll={{ y: tableScrollHeight }}
            />
          </section>
        </>
      )}
    </article>
  )
}
