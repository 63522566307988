import { StarFilled, StarOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { FC, useState } from 'react'

import { CarrierDto } from 'trellis:api/carrier/carrier-client'
import { ToggleCarrierPreference } from 'trellis:api/carrier/carrierApi'
import { NotifyText } from 'trellis:constants/notifyText'
import { showMessage } from 'trellis:utilities/general'

interface FavoriteCellProps {
  handleCarrierPreferenceChange: (
    record: CarrierDto,
    isFavorite: boolean,
  ) => void
  record: CarrierDto
  value: boolean
}

const FavoriteCell: FC<FavoriteCellProps> = ({
  handleCarrierPreferenceChange,
  record,
  value,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(value)

  const setIsFavorite = async () => {
    setIsChecked(!isChecked)

    await ToggleCarrierPreference(record.Id)
      .then(() => {
        handleCarrierPreferenceChange(record, !isChecked)
        if (!value) showMessage(NotifyText.carrierAddedAsFavorite, 'success')
        else showMessage(NotifyText.carrierRemovedAsFavorite, 'success')
      })
      .catch(() => {
        showMessage(NotifyText.carrierPreferenceFail)
        setIsChecked(value)
      })
  }

  return (
    <Tooltip
      mouseEnterDelay={1}
      title={isChecked ? 'Remove from Favorites' : 'Add to Favorites'}
    >
      <div onClick={() => setIsFavorite()}>
        {/* TODO: in a follow up story, replace star icons withs rate here */}
        {/* <Rate count={1} /> */}
        {isChecked ? (
          <StarFilled
            className='star-check'
            style={{ color: 'var(--color-primary, #91C73D)' }}
          />
        ) : (
          <StarOutlined
            className='star-check'
            style={{ color: 'var(--charcoal-100, #BFBFBF)' }}
          />
        )}
      </div>
    </Tooltip>
  )
}

export default FavoriteCell
